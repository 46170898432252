<template>
  <div
    class="digital-tights"
    :style="$route.name === 'mall' ? `height:${Height}px` : ''"
  >
    <div class="title">
      <div class="title-main">
        <h2>Digital equity<span>数字权益</span></h2>
        <div class="cls" @click="ClsShow = !ClsShow">
          <svg-icon icon-class="SGS-class"></svg-icon>
          <p>分类</p>
          <ul v-show="ClsShow">
            <li
              v-for="list in ClsData"
              :key="list.id"
              @click.stop="selectionSort(list.id)"
            >
              {{ list.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "digital-tights",

  data() {
    return {
      Height: 774,
      ClsShow: false,
      ClsData: [
        {
          title: "运动",
          id: 1,
        },
        {
          title: "服装",
          id: 2,
        },
        {
          title: "陶瓷",
          id: 3,
        },
        {
          title: "艺术品",
          id: 4,
        },
        {
          title: "其他",
          id: 0,
        },
      ],
    };
  },
  created() {
    // console.log(document.body.scrollHeight);
    // this.$nextTick(function () {
    //   console.log(document.body.clientHeight);
    //   this.Height = document.body.clientHeight - 162;
    //   console.log(this.Height);
    // });
  },

  methods: {
    selectionSort(id) {
      this.ClsShow = false;
      console.log(this.ClsShow);
      // console.log(id);
      this.$router.replace({ path: `/DigitalRights?id=${id}` });
    },
  },
};
</script>

<style lang="scss" scoped>
.digital-tights {
  border-top: 1px solid #00aeeb;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .title {
    width: 100%;
    height: 80px;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    z-index: 1;
    .title-main {
      position: relative;
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      line-height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        font-size: 28px;
        font-family: SF UI Display;
        font-weight: 600;
        color: #333333;
        span {
          font-family: FZZhengHeiS-DB-GB;
          font-weight: 500;
          margin-left: 12px;
        }
      }
      .cls {
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #333333;
        // height: 28px;
        p {
          font-family: PingFang SC;
          font-weight: 600;
          margin-left: 4px;
          line-height: 28px;
        }
        ul {
          position: absolute;
          width: 140px;
          top: 60px;
          right: -40px;
          background: #fff;
          padding: 20px 24px;
          box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);

          li {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 14px;
            color: #666666;
            margin-bottom: 24px;
            cursor: pointer;
          }
          li:last-child {
            margin-bottom: 0;
          }
          li:hover {
            color: #00aeeb;
          }
        }
      }
    }
  }
}
</style>